/**
 * @module ViewApp
 */

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  ShowButton,
  UrlField,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  required,
  Create,
  Filter,
} from 'react-admin'

function FeatureFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="name" alwaysOn={true} />
    </Filter>
  )
}

/**
 * Feature list react component.
 *
 * @param {object} props - Properties to customize the list.
 * @returns {React.ReactElement} - The component.
 *
 */
export function FeatureList(props) {
  return (
    <List {...props} filters={<FeatureFilter />}>
      <Datagrid>
        <TextField source="name" sortable={false} />
        <TextField source="id" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}

/**
 * Feature layout react component.
 *
 * @param {object} props - Properties to customize the layout.
 * @returns {React.ReactElement} - The component.
 *
 */
export function FeatureShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <UrlField source="link" target="_blank" />
        <TextField source="updated_dt" />
        <TextField source="source" />
      </SimpleShowLayout>
    </Show>
  )
}

/**
 * Feature editor react component.
 *
 * @param {object} props - Properties to customize the editor.
 * @returns {React.ReactElement} - The component.
 *
 */
export function FeatureEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="id" disabled={true} />
        <TextInput source="name" validate={[required()]} />
        <TextInput source="link" multiline={true} validate={[required()]} />
        <TextInput source="updated_dt" disabled={true} />
        <TextInput source="source" disabled={true} />
      </SimpleForm>
    </Edit>
  )
}

/**
 * Feature creator react component.
 *
 * @param {object} props - Properties to customize the creator.
 * @returns {React.ReactElement} - The component.
 *
 */
export function FeatureCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="link" validate={[required()]} multiline={true} />
        <TextInput source="source" initialValue="admin" disabled={true} />
      </SimpleForm>
    </Create>
  )
}
