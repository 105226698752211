/**
 * @module ViewApp
 */

import React from 'react'
import { SignInForm } from 'components/ui'

/**
 * App Unauthenticated react component.
 *
 * @returns {React.ReactElement} - The app.
 *
 */
export function AppUnauthenticated() {
  return <SignInForm />
}
