/**
 * @module SeekClients
 */

import { AuthClient } from '@youversion/api/4.0/core'
import { getAPIEnvironment } from '@youversion/utils'
import { stringify } from 'query-string'
import { fetchUtils } from 'react-admin'
import urlReplacer from 'utils/url-replacer'

const env = getAPIEnvironment()

let searchAPIUrl = 'https://search.youversionapistaging.com/4.0'
let moviesAPIUrl = 'https://movies.youversionapistaging.com/4.0'
let graphqlUrl = 'https://presentation.youversionapistaging.com/graphql'
if (env === 'production') {
  searchAPIUrl = 'https://search.youversionapi.com/4.0'
  moviesAPIUrl = 'https://movies.youversionapi.com/4.0'
  graphqlUrl = 'https://presentation.youversionapi.com/graphql'
} else if (env === 'test') {
  searchAPIUrl = 'http://localhost:9000/4.0'
}

const fetchJson = async (url, options = {}) => {
  const tokenResponse = await AuthClient.getToken()

  const requestOptions = options
  if (!requestOptions.headers) {
    requestOptions.headers = new Headers({
      Accept: 'application/json',
      Referer: 'http://yvapi.youversionapi.com',
      'User-Agent': 'yv api python script',
      'Accept-Encoding': 'gzip',
      'Accept-Language': 'en',
      'Content-Type': 'application/json',
    })
  }

  if (url.includes('search') || url.includes('http://localhost:9000/4.0')) {
    requestOptions.headers.set(
      'Authorization',
      `Bearer ${tokenResponse.access_token}`,
    )
  }

  if (url.includes('movies', 'graphql')) {
    requestOptions.headers.set('X-YouVersion-App-Version', '3')
    requestOptions.headers.set('X-YouVersion-Client', 'youversion')
    requestOptions.headers.set('X-YouVersion-App-Platform', 'web')
  }

  return fetchUtils.fetchJson(url, requestOptions)
}

const httpClient = fetchJson

const videoConfig = httpClient(`${moviesAPIUrl}/configuration`).then(
  (configJson) => {
    return {
      url: configJson.json.share_url,
      thumbnail: configJson.json.image_urls.video,
    }
  },
)

const { red } = require('@youversion-red/red-framework')
const core = require('@youversion-red/core')
const { bible } = require('@youversion-red/bible').youversion.red
const bibleShared = require('@youversion-red/bible-shared').youversion.red.bible

function initialize(clientId, clientSecret, googleWebClientId) {
  const context = new core.red.WebContext()
  core.red.platform.http.RequestManager.staging = true
  // if (core.red.platform.http.RequestManager.staging) {
  //     core.red.platform.Log.level = core.red.platform.LogLevel.DEBUG;
  // }
  red.REDInitializer.initialize(
    context,
    '1.0',
    clientId || '166fd59221da4be18891ab1bffbfd4fa',
    clientSecret || '95d46b19b7b648f1985346d308c5ee56',
    null,
    googleWebClientId || '',
    null,
  )
}

async function getBible(usfm) {
  const reference = bibleShared.reference.newBibleReference(usfm, 111)

  const verseContent = await bible.service.getVerses(
    bible.service.BibleService,
    reference,
  )
  return verseContent
}

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    const pageOffset = (page - 1) * perPage
    const perPageOffset = pageOffset + perPage
    let query

    switch (resource) {
      case 'features': {
        query = { query: '*' }
        if (Object.prototype.hasOwnProperty.call(params.filter, 'name')) {
          query = { query: params.filter.name }
        }
        break
      }
      case 'topics': {
        query = { query: '*' }
        if (Object.prototype.hasOwnProperty.call(params.filter, 'text')) {
          query = { query: params.filter.text }
        }
        break
      }
      default: {
        query = params.filter
      }
    }

    const url = `${searchAPIUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ json }) => ({
      data: json.data.slice(pageOffset, perPageOffset),
      total: json.data.length,
    }))
  },

  getOne: (resource, params) => {
    return httpClient(
      `${searchAPIUrl}/${resource}/${params.id}`,
    ).then(({ json }) => ({ data: json }))
  },

  getMany: async (resource, params) => {
    let calls
    switch (resource) {
      case 'videos': {
        const videoUrls = await videoConfig

        calls = params.ids.map((id) => {
          return httpClient(`${moviesAPIUrl}/videos/${id}`).then(({ json }) => {
            const videoDetails = Object.assign(json, videoUrls)
            videoDetails.thumbnail = urlReplacer(videoDetails.thumbnail, {
              width: '500',
              height: '250',
              id,
              language: 'en',
            })
            videoDetails.url = urlReplacer(videoDetails.url, {
              id,
              language: 'en',
            })
            return videoDetails
          })
        })
        break
      }
      case 'verses': {
        initialize()
        calls = params.ids.map((usfm) => {
          return getBible(usfm).then((data) => {
            const verseDetails = {
              id: usfm,
              content: data[0].content,
              reference: data[0].reference.human,
            }

            return verseDetails
          })
        })
        break
      }
      case 'plans': {
        calls = params.ids.map((id) => {
          return httpClient(graphqlUrl, {
            method: 'POST',
            body: JSON.stringify({
              query: `
{
  getPlan(id: ${id}) {
    name
    images {
      url
    }
  }
}
`,
            }),
          }).then(({ json }) => {
            const plan = json.data.getPlan
            const planDetails = {
              id,
              title: plan.name.default,
              thumbnail: plan.images[0].url,
              url: `https://www.bible.com/reading-plans/${id}`,
            }

            return planDetails
          })
        })
        break
      }
      default:
      // eslint requires a default case
    }
    return Promise.all(calls).then((data) => {
      return { data }
    })
  },

  update: (resource, params) => {
    const payload = params.data
    delete payload.id // Don't send id, it is read only
    delete payload.updated_dt // Don't send updated_dt, it is read only

    switch (resource) {
      case 'features': {
        break
      }
      case 'elevations': {
        const idFields = ['plan_ids', 'video_ids', 'church_ids']
        idFields.forEach((field) => {
          if (payload[field] === null) {
            payload[field] = []
          } else if (typeof payload[field] === 'string') {
            payload[field] = payload[field].split(',').map(Number)
          }
        })

        if (!payload.usfms) {
          payload.usfms = []
        } else {
          payload.usfms = payload.usfms.split(',')
        }

        if (!payload.context) {
          payload.context = ''
        }

        payload.translate = true
        break
      }
      default:
      // eslint requires a default case, but it is not actually needed here
    }

    return httpClient(`${searchAPIUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) => {
    const payload = params.data

    switch (resource) {
      case 'elevations': {
        const idFields = ['plan_ids', 'video_ids', 'church_ids']
        idFields.forEach((field) => {
          if (!payload[field]) {
            payload[field] = []
          } else {
            payload[field] = payload[field].split(',').map(Number)
          }
        })

        if (!payload.usfms) {
          payload.usfms = []
        } else {
          payload.usfms = payload.usfms.split(',')
        }

        if (!payload.context) {
          payload.context = ''
        }

        payload.translate = true
        break
      }
      default:
      // no tweaking is needed when creating a feature so it falls under default
    }

    return httpClient(`${searchAPIUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(payload),
    }).then(({ json }) => ({ data: { ...payload, id: json.id } }))
  },
}
