/**
 * @module Utils
 */

/**
 * Replace aspects of a URL.
 *
 * @param {string} sourceString - Which string to replace from.
 * @param {object} params - Params to specify what is replaced.
 *
 * @returns {string} - The replaced URL result.
 *
 */
export default function urlReplacer(sourceString = '', params = {}) {
  let transformString = sourceString
  Object.keys(params).forEach((key) => {
    const value = params[key]
    const rx = new RegExp(`{${key}}`, 'g')
    transformString = transformString.replace(rx, value)
  })
  return transformString
}
