/**
 * @module SignInUI
 */

import React from 'react'
import PropTypes from 'prop-types'
import {
  AsyncForm,
  FormActions,
  FormContent,
  FormError,
  FormLoadError,
  FormLoading,
} from '@youversion/react/components'

import { useSignInModel } from '@youversion/react/providers'
import {
  Avatar,
  Button,
  TextField,
  Typography,
  makeStyles,
  Container,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function SignInFormError({ error }) {
  return <Alert severity="error">{error.message}</Alert>
}
SignInFormError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.instanceOf(Error)]),
}
SignInFormError.defaultProps = {
  error: null,
}

function SignInFormActions({ status }) {
  const classes = useStyles()
  return (
    <Button
      type="submit"
      fullWidth={true}
      variant="contained"
      color="primary"
      className={classes.submit}
      disabled={Boolean(status === 'loading')}
    >
      Sign In
    </Button>
  )
}
SignInFormActions.propTypes = {
  status: PropTypes.string,
}
SignInFormActions.defaultProps = {
  status: 'loading',
}

function SignInFormContent({
  signInModel,
  handleAttributeChange,
  handleBlur,
  isTouched,
}) {
  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required={true}
        fullWidth={true}
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus={true}
        value={signInModel.email.$value}
        onChange={handleAttributeChange}
        onBlur={handleBlur}
        error={Boolean(isTouched('email') && signInModel.email.$error)}
        helperText={
          isTouched('email') && signInModel.email.$error
            ? signInModel.email.$error
            : ''
        }
      />

      <TextField
        variant="outlined"
        margin="normal"
        required={true}
        fullWidth={true}
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={signInModel.password.$value}
        onChange={handleAttributeChange}
        onBlur={handleBlur}
        error={Boolean(isTouched('password') && signInModel.password.$error)}
        helperText={
          isTouched('password') && signInModel.password.$error
            ? signInModel.password.$error
            : ''
        }
      />
    </>
  )
}
SignInFormContent.propTypes = {
  signInModel: PropTypes.any,
  handleAttributeChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isTouched: PropTypes.func.isRequired,
}
SignInFormContent.defaultProps = {
  signInModel: {},
}

/**
 * A sign-in form containing relevant user login fields.
 *
 * @param {object} prop - The component props.
 * @param {boolean} prop.shouldRefresh - Whether or not to refresh the page after successful sign-in.
 *
 * @returns {React.ReactElement} - The component containing username/password inputs.
 *
 */
export function SignInForm({ shouldRefresh = false }) {
  const classes = useStyles()
  const {
    model: signInModel,
    handleAttributeChange,
    handleBlur,
    handleSave: handleSignIn,
    handleDelete,
    isTouched,
    status,
    error,
  } = useSignInModel()

  const handleSignInAndRefresh = async () => {
    await handleSignIn()
    window.location.reload()
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <AsyncForm
          status={status}
          error={error}
          onSave={shouldRefresh ? handleSignInAndRefresh : handleSignIn}
          onDelete={handleDelete}
        >
          <FormLoadError>
            <SignInFormError />
          </FormLoadError>

          <FormLoading>
            <CircularProgress />
          </FormLoading>

          <FormError>
            <SignInFormError />
          </FormError>

          <FormContent>
            <SignInFormContent
              handleAttributeChange={handleAttributeChange}
              signInModel={signInModel}
              handleBlur={handleBlur}
              isTouched={isTouched}
            />
          </FormContent>

          <FormActions>
            <SignInFormActions />
          </FormActions>
        </AsyncForm>
      </div>
    </Container>
  )
}
SignInForm.propTypes = {
  shouldRefresh: PropTypes.bool,
}
