/**
 * @module App
 */

import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, CssBaseline, CircularProgress } from '@material-ui/core'
import { createV3Theme } from '@youversion/react/styles'
import {
  YouVersionAuthProvider,
  YouVersionAuthContent,
  YouVersionAuthError,
  YouVersionAuthLoading,
} from '@youversion/react/providers'
import { AppAuthenticated } from 'views/app-authenticated'
import { AppUnauthenticated } from 'views/app-unauthenticated'
import { SignInForm } from 'components/ui'

const theme = createV3Theme('light')

function AuthContent({ isSignedIn, hasValidScopes }) {
  return isSignedIn && hasValidScopes ? (
    <AppAuthenticated />
  ) : (
    <AppUnauthenticated />
  )
}
AuthContent.propTypes = {
  isSignedIn: PropTypes.bool,
  hasValidScopes: PropTypes.bool,
}
AuthContent.defaultProps = {
  isSignedIn: false,
  hasValidScopes: false,
}

/**
 * A react app full of relevant content.
 *
 * @returns {React.ReactElement} - The component.
 *
 */
export function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <YouVersionAuthProvider scopes="read_search write_search">
          <YouVersionAuthError>
            <SignInForm shouldRefresh={true} />
          </YouVersionAuthError>
          <YouVersionAuthLoading>
            <CircularProgress />
          </YouVersionAuthLoading>
          <YouVersionAuthContent>
            <AuthContent />
          </YouVersionAuthContent>
        </YouVersionAuthProvider>
      </ThemeProvider>
    </>
  )
}
