/**
 * @module ViewApp
 */

import React from 'react'
import { Card, CardContent } from '@material-ui/core'
import { Title } from 'react-admin'

/**
 * Dashboard react component.
 *
 * @returns {React.ReactElement} - The dashboard.
 *
 */
export function Dashboard() {
  return (
    <Card>
      <Title title="Seek - YouVersion" />
      <CardContent>Welcome to Seek, the Search administrator.</CardContent>
    </Card>
  )
}
