/**
 * @module ViewApp
 */

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  List,
  Show,
  Datagrid,
  SimpleShowLayout,
  TextField,
  ShowButton,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  Create,
  required,
  ReferenceArrayField,
  ImageField,
  UrlField,
  Filter,
} from 'react-admin'

/**
 * Elevation filter react component.
 *
 * @param {object} props - Properties to customize the filter.
 * @returns {React.ReactElement} - The component.
 *
 */
export function ElevationFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="query" alwaysOn={true} />
    </Filter>
  )
}

/**
 * Elevation List react component.
 *
 * @param {object} props - Properties to customize the list.
 * @returns {React.ReactElement} - The component.
 *
 */
export function ElevationList(props) {
  return (
    <List {...props} linktype="show" filters={<ElevationFilter />}>
      <Datagrid>
        <TextField source="query" sortable={false} />
        <TextField source="id" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}

/**
 * Elevation layout react component.
 *
 * @param {object} props - Properties to customize the layout.
 * @returns {React.ReactElement} - The component.
 *
 */
export function ElevationShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="query" />
        <TextField source="usfms" />
        <ReferenceArrayField
          source="usfms"
          reference="verses"
          label="Bible Verses"
        >
          <Datagrid>
            <TextField source="reference" sortable={false} />
            <TextField source="content" sortable={false} />
          </Datagrid>
        </ReferenceArrayField>
        <TextField source="plan_ids" />
        <ReferenceArrayField
          source="plan_ids"
          reference="plans"
          label="Plan Details"
        >
          <Datagrid>
            <TextField source="id" sortable={false} />
            <TextField source="title" sortable={false} />
            <ImageField source="thumbnail" title="thumbnail" sortable={false} />
            <UrlField source="url" target="_blank" sortable={false} />
          </Datagrid>
        </ReferenceArrayField>
        <TextField source="video_ids" />
        <ReferenceArrayField
          source="video_ids"
          reference="videos"
          label="Video Details"
        >
          <Datagrid>
            <TextField source="id" sortable={false} />
            <TextField source="title" sortable={false} />
            <ImageField source="thumbnail" title="thumbnail" sortable={false} />
            <UrlField source="url" target="_blank" sortable={false} />
          </Datagrid>
        </ReferenceArrayField>
        <TextField source="church_ids" />
        <TextField source="context" />
        <TextField source="updated_dt" />
      </SimpleShowLayout>
    </Show>
  )
}

/**
 * Elevation edit react component.
 *
 * @param {object} props - Properties to customize the editor.
 * @returns {React.ReactElement} - The component.
 *
 */
export function ElevationEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled={true} source="id" />
        <TextInput source="query" helperText="The query entered by a user" />
        <TextInput
          multiline={true}
          source="usfms"
          helperText="Bible Verses that are topically-related to this query"
        />
        <TextInput
          source="plan_ids"
          helperText="Plans that should be recommended for this query"
        />
        <TextInput
          source="video_ids"
          helperText="Videos with relevant content for this query"
        />
        <TextInput
          source="church_ids"
          helperText="Churches that match this query"
        />
        <TextInput
          multiline={true}
          source="context"
          helperText="Contextual info about the query string to clarify the string being translated in Crowdin"
        />
      </SimpleForm>
    </Edit>
  )
}

/**
 * Elevation create react component.
 *
 * @param {object} props - Properties to customize the creator.
 * @returns {React.ReactElement} - The component.
 *
 */
export function ElevationCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source="query"
          validate={[required()]}
          helperText="The query entered by a user"
        />
        <TextInput
          multiline={true}
          source="usfms"
          helperText="Bible Verses that are topically-related to this query"
        />
        <TextInput
          source="plan_ids"
          helperText="Plans that should be recommended for this query"
        />
        <TextInput
          source="video_ids"
          helperText="Videos with relevant content for this query"
        />
        <TextInput
          source="church_ids"
          helperText="Churches that match this query"
        />
        <TextInput
          multiline={true}
          source="context"
          helperText="Contextual info about the query string to clarify the string being translated in Crowdin"
        />
      </SimpleForm>
    </Create>
  )
}
