/**
 * @module ViewApp
 */

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { List, Datagrid, TextField, Filter, TextInput } from 'react-admin'

function TopicFilter(props) {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="text" alwaysOn={true} />
    </Filter>
  )
}

/**
 * Topic list react component.
 *
 * @param {object} props - Properties to customize the list.
 * @returns {React.ReactElement} - The component.
 *
 */
export function TopicList(props) {
  return (
    <List {...props} filters={<TopicFilter />}>
      <Datagrid>
        <TextField source="text" sortable={false} />
        <TextField source="id" sortable={false} />
        <TextField source="subtopics" />
      </Datagrid>
    </List>
  )
}
